.alert-message ul {
  margin-bottom: 0;
}

.alert-message--warning ul {
  padding-left: 0;
  color: var(--link-color-action-text-primary);
}

.alert-message--error ul {
  color: var(--alert-message-color-feedback-border-error);
}

.alert-message ul li {
  text-decoration: underline;
  word-wrap: break-word;
}

.alert-message ul li:first-child {
  margin-top: var(--spacing-s);
}

.alert-message--warning ul li {
  list-style: none;
}

.alert-message--warning ul li.message {
  text-decoration: none;
  color: var(--color-universal-secondary-b);
}

.alert-message--error ul li {
  list-style: disc;
}
