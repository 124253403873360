.country-code-and-phone-number-select {
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  gap: 5px;

  .select-label {
    font-weight: var(--fw-regular);
    font-size: var(--fs-base);
    line-height: var(--lh-base);
  }

  .browser-default-select-wrapper {
    display: inline-flex;
    flex-direction: row;
    height: unset;
    padding: 0px;
    border: unset;
    cursor: pointer;
    border-radius: var(--border-radius-s);

    .open-close-svg {
      transform: translate(-24px, -2px);
    }

    select {
      width: unset;
      height: unset;
      margin: 0;
      border: 0;
      padding: 13px 20px 13px 8px;
      font-weight: var(--fw-regular);
      font-size: var(--fs-s);
      line-height: var(--lh-s);
    }
  }

  .browser-default-select-wrapper:focus-visible {
    outline: 2px solid var(--form-color-interaction-border-primary-focus);
    border: 2px solid transparent;
    color: var(--form-color-interaction-text-primary-focus);
  }

  .input-box {
    display: inline-flex;
    flex-direction: row;
    background-color: var(--form-color-interaction-background-primary);
    border: 2px solid var(--form-color-interaction-border-primary);
    border-radius: var(--border-radius-s);
    align-items: center;
    padding: 13px 12px;
    width: 100%;
    font-weight: var(--fw-regular);
    font-size: var(--fs-s);
    line-height: var(--lh-s);

    .country-code {
      width: 70px;
    }

    input {
      border: unset;
      width: 100%;
      background-color: var(--form-color-interaction-background-primary);
      font-weight: var(--fw-regular);
      font-size: var(--fs-s);
      line-height: var(--lh-s);
    }

    input::-webkit-inner-spin-button,
    input::-webkit-outer-spin-button {
      appearance: none;
    }

    input:hover {
      background: var(--form-color-interaction-background-primary-hover);
    }

    input:focus {
      outline: unset;
      border: unset;
      background: var(--form-color-interaction-background-primary-hover);
    }

    input:active {
      outline: unset;
    }
  }

  .input-box:hover {
    border: 2px solid var(--form-color-interaction-border-primary-hover);
    background: var(--form-color-interaction-background-primary-hover);
  }

  .input-box:focus-within {
    border: 2px solid transparent;
    color: var(--form-color-interaction-text-primary-focus);
  }

  .input-box:active {
    outline: 2px solid var(--form-color-interaction-border-primary-focus);
    border: 2px solid transparent;
    color: var(--form-color-interaction-text-primary-focus);
  }

  .browser-default-select-wrapper {
    .input-box.error {
      border: 2px solid var(--component-color-feedback-danger);
    }
  }
}
