.pseudo-link {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background: transparent;
  color: var(--link-color-action-text-primary);
  border: none;
  text-decoration: none;
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-regular);
  padding: 0;
  outline: none;
  -webkit-appearance: none;
  padding: 5px 10px;
}

.folderadded-link {
  padding-left: 0;
}

.pseudo-link .icon-box {
  width: var(--icon-sizing-s);
}

.pseudo-link .icon-box svg {
  width: 100%;
  stroke: var(--link-color-action-text-primary);
}

.pseudo-link-icon-left {
  flex-direction: row-reverse;
}

.pseudo-link:not(.dark):not(.bold):not(.light) .pseudo-link-text:hover {
  color: var(--color-universal-secondary-h);
  border-bottom: 1px solid var(--color-universal-secondary-h);
}

.dark {
  background: transparent;
  border-radius: var(--border-radius-s);
}

.pseudo-link.bold:hover {
  font-weight: var(--fw-bold);
}

.pseudo-link.dark:hover {
  background: var(--component-color-interaction-background-secondary-hover);
  border-radius: var(--border-radius-s);
}

.pseudo-link.light:hover {
  background: var(--component-color-interaction-background-primary-hover);
  border-radius: var(--border-radius-s);
}

.pseudo-link-text {
  border-bottom: 1px solid var(--link-color-action-text-primary);
  color: var(--link-color-action-text-primary);
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-regular);
}

.pseudo-link.small .pseudo-link-text {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
}

.pseudo-link:disabled {
  cursor: pointer;
  pointer-events: none;
  opacity: 0.5;
}
