.page-container {
  min-height: 100vh;
  display: flex;
  height: auto;
}

.page-container__right-content,
.page-container__right-content--welcome {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Applies only to login/register journey */
.page-container__right-content--welcome > div {
  padding: 112px 105px 70px 105px;
}

/* Footer */
.page-container__right-content > div {
  padding: 40px 60px;
}

/* Applies to all screens after the login/register journey */
.page-container__right-content > div:not(:last-child) {
  /* All divs except footer as logo in footer purposefully misaligned with content*/
  padding: 40px 100px 0px 60px;
}

.page-container__right-content > .panel.toast.toast--success.fade-in-then-out {
  padding: var(--spacing-m) 45px var(--spacing-m) 43px;
  z-index: var(--z-index-4plus1);
}

.page-container .page-container__bottom-logo {
  padding-top: 40px;
  padding-bottom: 40px;
  margin-left: auto;
  margin-top: auto;
}

.page-container__main-menu > div:first-child {
  padding-bottom: var(--spacing-s);
}

@media screen and (max-width: 1229px) {
  .page-container__main-menu {
    display: none;
  }

  .page-container__right-content > div {
    padding-top: 50px;
    padding-right: 30px;
    padding-left: 30px;
  }

  .page-container__right-content--welcome {
    height: 90%;
  }

  .page-container__right-content--welcome > div {
    padding-top: 50px;
    padding-right: 50px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 1229px) {
  .page-container {
    display: contents;
  }

  .page-container .page-container__bottom-logo {
    justify-content: center;
  }

  .page-container__right-content > div:not(:last-child) {
    padding: 60px 30px 0px 30px;
  }
}

@media only screen and (max-width: 743px) {
  .page-container__right-content > div:not(:last-child) {
    padding-top: 30px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .page-container__right-content--welcome > div {
    padding-top: 40px;
    padding-right: 20px;
    padding-left: 20px;
  }

  .page-container__bottom-logo {
    margin-left: auto;
    margin-right: auto;
  }
}

/* START OF MAIN MENU ON NON-WELCOME SCREENS */
.page-container__main-menu .page-container__main-menu-content-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 50px;
}

.page-container__main-menu
  .page-container__main-menu-content-header
  .page-container__main-menu-content-header--right {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-container__main-menu .page-container__main-menu--search-bar {
  width: 100%;
}

.page-container__main-menu
  .page-container__main-menu-content-header
  .page-container__main-menu-content-header--right
  span,
.page-container__main-menu
  .page-container__main-menu-content-header
  .page-container__main-menu-content-header--right
  a {
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
  line-height: var(--lh-m);
}

.page-container .account-button button {
  height: 44px;
  width: 44px;
  outline: unset;
  border: unset;
}

.page-container__main-menu .hide-on-tablet-and-down button {
  box-shadow: none;
}

.page-container__main-menu
  .page-container__main-menu-content-header--right
  .logout-link {
  margin: 0;
  padding: 5px 0;
}
.page-container__main-menu
  .page-container__main-menu-content-header--right
  .notification-icon {
  margin: 0;
  padding: 5px 0;
}
.page-container__main-menu
  .page-container__main-menu-content-header--right
  .logout-link:focus,
.page-container__main-menu
  .page-container__main-menu-content-header--right
  .notification-icon:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
  border-radius: var(--border-radius-s);
}

.page-container__main-menu .page-container__main-menu-content-header--divider {
  border-left: 1px solid var(--divider-color-context-background-primary);
  height: 24px;
  margin: 0 18px;
}

.page-container__main-menu
  .page-container__main-menu-content__header--right
  span {
  margin: 0;
}

.page-container__main-menu
  .page-container__main-menu-content__header--right
  svg {
  margin-right: 10px;
}

.page-container__main-menu
  .page-container__main-menu-content__header--right
  .account-button {
  margin: 0;
}

.page-container__main-menu
  .page-container__main-menu-content__header--right
  .account-button
  button {
  height: 44px;
  width: 44px;
  border: none;
  outline: none;
}

/* END OF MAIN MENU ON NON-WELCOME SCREENS */

/* START OF CASE MANAGER SCREENS */

.left-navigation .left-nav-content--sticky .logo-with-title-container {
  display: flex;
  flex-direction: column;
  margin: 44px 44px 0px 44px;
  gap: 20px;
  color: var(--navbar-color-interaction-text);
}

/* END OF CASE MANAGER SCREENS */

/* START OF CONTACT SCREENS */

.page-container__main-menu
  .page-container__main-menu-content-header
  .page-container__main-menu-content-header--no-border {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.page-container__main-menu
  .page-container__main-menu-content-header
  .page-container__main-menu-content-header--no-border
  span,
.page-container__main-menu
  .page-container__main-menu-content-header
  .page-container__main-menu-content-header--no-border
  a {
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
  line-height: var(--lh-m);
  border: none;
}

.page-container__main-menu
  .page-container__main-menu-content-header
  .page-container__main-menu-content-header--no-border
  a:focus {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.page-container__main-menu
  .page-container__main-menu-content-header--no-border
  .logout-link {
  margin: 0;
  padding: 5px 0;
}
.page-container__main-menu
  .page-container__main-menu-content-header--no-border
  .notification-icon {
  margin: 0;
  padding: 5px 0;
}

/* END OF CONTACT SCREENS */
