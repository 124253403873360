.page-wrapper {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
}

.page_wrapper .left-navigation {
  grid-column: 1/2;
}

.main-layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  grid-column: 2/3;
}

.search-bar-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top: var(--spacing-l);
  padding-bottom: var(--spacing-s);
  gap: var(--spacing-s);
}

.organisations-page-container, .clients-page-container {
  padding-top: var(--spacing-l);
}

.page-content {
  flex-grow: 1;
}

.prevent-click {
  pointer-events: none;
}

.page-content.onex {
  width: 100%;
  margin-right: 20px;
}

@media (max-width: 1480px) {
  .organisations-page-container, .clients-page-container {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .page-content.onex {
    width: 100%;
    max-width: 2110px;
    padding: 0px 20px;
    margin-right: 20px;
  }
}