.remove-node{
  padding: 60px 0 30px 60px;
}

.remove-node .node-name{
  font-size: var(--fs-l);
  font-weight: var(--fw-light);
  margin-bottom: 10px;
}

.remove-node .node-content{
  margin-bottom: 10px;
}

.remove-node.subtitle svg{
  margin-right: 10px;
}

.remove-node .icon-box{
  width: var(--icon-sizing-s);
}

.remove-node .modal-footer{
  padding: 30px 60px 30px 60px;
}

@media (max-width: 767px){
  .remove-node{
    padding: 40px 20px 20px 20px;
  }

  .remove-node .close-button {
    top: 44px;
  }

  .remove-node .modal-content-wrapper {
    padding: 0px;
  }

  .remove-node .node-name {
    line-height: var(--lh-l);
  }

  .remove-node .modal-footer{
    padding: 20px;
  }
}
