.banner-wrapper-large-1 {
  position: relative;
  width: 99px;
  min-width: 99px;
}

.banner-wrapper-large-2 {
  position: relative;
  width: 100px;
  min-width: 100px;
}

.banner-label {
  display: flex;
  height: inherit;
  width: inherit;
  text-align: center;
  vertical-align: middle;

  span {
    width: -webkit-fill-available;
    position: absolute;
    bottom: 0;
    max-width: 100px;
    font-weight: 500;
    font-size: var(--fs-xs);
    letter-spacing: var(--letter-spacing-base);
    padding-top: 1px;
    background-color: var(--color-universal-secondary-h);
    border-bottom-left-radius: var(--border-radius-s);
    border-bottom-right-radius: var(--border-radius-s);
    color: var(--color-universal-natural-white);
  }

  &[aria-label="Pending"] span {
    color: white;
  }
  &[aria-label="Failed"] span {
    background: var(--component-color-feedback-danger);
    color: white;
  }
}

// large-2 containers are shorter so the label (sibling) needs to be placed under it
.thumbnail-container.large-2 + div .banner-label span {
  bottom: -17px;
}
