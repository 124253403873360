.delete-case-file {
  .header {
    font-weight: var(--fw-medium);
    padding-bottom: 20px;
  }

  .node-name {
    font-size: var(--fs-base);
    line-height: var(--lh-base);

    .node-name__file_name {
      font-weight: var(--fw-bold);
      padding-left: 10px;
    }
  }

  .button.primary {
    span {
      font-size: var(--fs-base);
      line-height: var(--lh-base);
      font-weight: var(--fw-medium);
    }

    .loadingspinner {
      margin-left: 10px;
    }
  }
}
