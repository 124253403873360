.create-contact-modal,
.review-changes-modal {
  --standard-modal-gap: 27px;
}

.create-contact-modal {
  font-family: var(--body-font-text-base);
}

.modal-standard-p-top-bottom {
  padding: var(--standard-modal-gap) 0;
}

.modal-standard-m-top-bottom {
  margin: var(--standard-modal-gap) 0;
}

.create-contact-main {
  row-gap: var(--spacing-l);
}

.create-contact-main .text-input {
  margin: 0;
}

.create-contact-text {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-light);
  margin-bottom: 0;
}

.create-contact-text.text-center.follow-up {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-light);
  margin-bottom: 0;
}

.main-contact-name-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: var(--spacing-m);
}

.create-contact-main {
  .country-code-and-phone-number-select {
    width: 100%;

    .input-box {
      height: 48px;
      border: 1px solid var(--form-color-interaction-border-primary);
      width: 100%;
      margin: 8px var(--spacing-xxx-s) 8px 0px;
    }

    .input-box:focus-within,
    .input-box:focus,
    .input-box:active {
      border: unset;
    }
  }
}

.create-contact-main .select-wrapper {
  flex-basis: 100%;
}

.personal-note-text {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  margin-bottom: var(--spacing-xx-s);
}

.create-contact-modal .contact-name {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  font-weight: var(--fw-bold);
  border-bottom: 1px dashed var(--color-universal-secondary-d);
  justify-content: space-between;
  align-items: center;
}

.create-contact-modal .contact-name .contact-email {
  font-weight: var(--fw-regular);
  margin: 15px 0 0 0;
}

.contact-note {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.contact-note__border-bottom--dashed {
  border-bottom: 1px dashed var(--color-universal-secondary-d);
}

.contact-note:not(:empty) {
  padding-bottom: var(--standard-modal-gap);
}

.invite-checkbox {
  margin-bottom: 10px;
}

.invite-checkbox .checkbox {
  width: var(--icon-sizing-m);
  height: var(--icon-sizing-m);
}

.create-contact-modal .checkbox svg,
.contact-sent-icon.icon-box svg {
  width: 100%;
  height: 100%;
}

.modal-content .contact-sent-icon.icon-box {
  width: 120px;
  height: 120px;
  margin-bottom: var(--spacing-m);
}

.invite-sent-header {
  font-weight: var(--fw-regular);
  font-size: var(--fs-l);
  line-height: var(--lh-l);
  margin-bottom: var(--spacing-m);
  color: var(--color-universal-secondary-b);
}

.contact-sent-text {
  margin-bottom: 22px;
}

.contact-invite-done-button {
  width: 347px;
}

@media (max-width: 1229px) {
  .create-contact-modal,
  .review-changes-modal {
    --standard-modal-gap: var(--spacing-m);
  }

  .create-contact-modal {
    margin-top: 0;

    .modal-content-wrapper {
      .modal-footer.unsaved-progress .unsaved-progress-buttons,
      .modal-footer.create-contact-footer {
        flex-direction: var(--modal-flex-layout-direction-tablet);
      }
    }
  }
}

@media (max-width: 767px) {
  .modal .modal-content.create-contact-modal {
    margin-bottom: var(--spacing-xxxx-l);
  }

  .main-contact-name-wrapper {
    grid-template-columns: unset;
    gap: var(--spacing-l);
  }

  .personal-note-text {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
    margin-bottom: var(--spacing-s);
  }

  .create-contact-text:not(.contact-sent-text) {
    font-size: var(--fs-base);
    line-height: var(--lh-base);
  }

  .create-contact-modal .contact-name {
    font-size: var(--fs-m);
    line-height: var(--lh-m);
  }

  .personal-note-button {
    margin: var(--spacing-s) 0;
  }

  .contact-note {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
  }

  .invite-sent-header {
    font-size: 28px;
    margin-bottom: var(--spacing-s);
  }

  .contact-sent-text {
    margin-bottom: var(--spacing-s);
  }

  //-moz-fit needed as fit-content not compatible with firefox and firefox for Android
  .modal-footer.create-contact-footer button {
    width: -moz-fit-content;
    width: fit-content;
  }

  .modal-footer button.button.contact-invite-done-button {
    width: 100%;
  }

  .create-contact-modal .col-6 {
    flex: 0 0 auto;
    width: 100%;
  }
  .create-contact-modal .pl-1 {
    padding: 0px;
  }
  .create-contact-modal .pr-1 {
    padding: 0px;
  }

  .create-contact-modal .modal-content.with-scrollbar {
    padding-right: var(--spacing-s);
    padding-bottom: var(--spacing-m);
  }

  .create-contact-modal {
    .modal-content-wrapper {
      .modal-footer.unsaved-progress .unsaved-progress-buttons,
      .modal-footer.create-contact-footer {
        position: relative;
        bottom: 0;
        flex-direction: var(--modal-flex-layout-direction-mobile);
        gap: 30px;
      }
    }
  }
  .modal-content-wrapper {
    .modal-footer.edit-permissions-footer {
      flex-direction: var(--modal-flex-layout-direction-mobile);
      gap: 30px;
    }
  }
}

.modal-content-wrapper {
  .modal-footer.unsaved-progress .unsaved-progress-buttons,
  .create-contact-footer {
    display: flex;
    justify-content: var(--modal-flex-layout-justify-content);
    flex-direction: var(--modal-flex-layout-direction-desktop);
  }

  .create-contact-footer {
    padding-top: var(--spacing-x-l);
    padding-bottom: var(--spacing-m);
  }
}
.create-contact-modal .create-contact-modal {
  padding-bottom: var(--spacing-m);
}

.create-contact-modal .mt-2 {
  margin-top: 5px;
}

.mb-2.create-contact-text {
  font-size: var(--fs-s);
}

.header.permissions {
  font-size: var(--fs-l);
}

.header.personal {
  font-size: var(--fs-l);
}

.header.summary {
  font-size: var(--fs-l);
}

.personal-note-text.fw-300 {
  font-size: var(--fs-s);
}

.pl-2.typography-light {
  font-size: var(--fs-s);
  font-weight: var(--fw-light);
}

.no-margin {
  font-size: var(--fs-l);
}

.contact-email {
  font-size: var(--fs-m);
}

/* Create Client */
.create-client-modal .main-client-name-wrapper {
  display: flex;
  flex-grow: 100;
  gap: 30px;
}

.create-client-modal.sub-header {
  padding-bottom: 30px;
}

.create-contact-modal .header.has-subheader {
  padding-bottom: 5px;
  border-bottom: unset;
}

.create-contact-modal .modal-content-wrapper h2 {
  padding-bottom: 30px;
}

.create-contact-modal .create-contact-main .selected-value-field,
.create-contact-modal .create-contact-main .outer-wrapper {
  height: 50px;
}

.create-contact-modal .create-contact-main .outer-wrapper:focus-within {
  border-radius: var(--border-radius-s);
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

/* coop specific styling */

// TODO: Move this to the 3 tier system https://dev.azure.com/secure-the-file/Application/_workitems/edit/15782
// This will take some time as theres a few cases here that need to be added in a very specific manner
.CO_OP {
  .modal {
    .header {
      font-size: var(--fs-xl);
      line-height: var(--lh-xl);
      font-weight: var(--fw-medium);
    }
    .header.sharing-file-permissions-header {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .modal-content {
      @media screen and (max-width: 767px) {
        padding-bottom: 0;
      }
    }

    @media screen and (max-width: 744px) {
      .modal-content.create-contact-modal {
        margin-bottom: 25px;
        padding-bottom: 0;
      }
    }

    .modal-content.with-scrollbar {
      @media (max-width: 767px) {
        padding-bottom: 0;
      }
    }

    .sharing-permissions {
      @media screen and (max-width: 767px) {
        margin-bottom: 0;
        padding-bottom: 80px;
      }

      .contact-sharing {
        .select-all {
          padding-bottom: 30px;
        }

        :nth-child(3) {
          padding-top: 30px;
          border-top: 1px solid var(--divider-color-context-background-primary);
        }

        @media screen and (min-width: 744px) {
          .item-row:last-of-type {
            padding-bottom: 0;
            margin-bottom: 0;
            border-bottom: unset;
          }
        }

        .item-row {
          padding-bottom: 30px;
          margin-bottom: 30px;
          border-bottom: 1px solid
            var(--divider-color-context-background-primary);

          .item-name {
            font-size: var(--fs-base);
            line-height: var(--lh-base);
            font-weight: var(--fw-medium);
          }
        }
      }

      @media screen and (max-width: 767px) {
        .radio-button-group .radio-div:first-of-type {
          gap: 50px;
        }

        .radio-button-group .radio-div {
          justify-content: unset;
          gap: 110px;
        }

        .radio-button-group {
          padding-bottom: 0;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    @media screen and (max-width: 767px) {
      .unsaved-progress {
        width: auto;

        //-moz-fit needed as fit-content not compatible with firefox and firefox for Android
        .unsaved-progress-buttons {
          button {
            width: -moz-fit-content;
            width: fit-content;
          }
        }
      }

      .extra-padding {
        padding: 0 var(--spacing-xxxx-l);
      }
    }
  }

  // TODO: this padding actually relates to the button sizing
  // currently the button sizing is set as a component prop
  // but it really should be set as a theme token: https://dev.azure.com/secure-the-file/Application/_workitems/edit/15642
  .contact-invite-done-button {
    padding: 16px 40px;
    width: unset;

    span {
      font-size: var(--fs-base);
      line-height: var(--lh-base);
    }
  }

  .create-contact-footer,
  .edit-permissions-footer {
    @media (max-width: 767px) {
      position: relative;
      margin-top: 0;
      padding-left: 0;
      bottom: 0;
    }
    //-moz-fit needed as fit-content not compatible with firefox and firefox for Android
    button.button {
      padding: 16px 40px;
      width: -moz-fit-content;
      width: fit-content;
    }
  }
}
