.file-details-buttons .btn-file-details {
  padding: 5px 10px;
}

.file-details-buttons {
  padding-bottom: 50px;
  margin-left: -10px;
}

.file-details-bottom-row {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 43px;
}

.file-details-buttons .file-details {
  padding: 5px 10px;
  margin: 10px;
}

.section-spacing .file-details-label {
  display: block;
  color: var(--color-universal-secondary-b);
  line-height: var(--lh-s);
  font-weight: var(--fw-bold);
  font-size: var(--fs-s);
}

.section-divider .file-details-label {
  font-weight: var(--fw-medium);
}
.section-divider .file-details-text {
  font-size: var(--fs-l);
  line-height: var(--lh-l);
}

.file-details-text {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  color: var(--color-universal-secondary-b);
  font-weight: 300 !important;
  word-break: break-all;
  margin-bottom: 0;
}

.file-details-text:not(:last-child) {
  margin-bottom: 30px;
}

.truncate-overflow {
  overflow: hidden;
  position: relative;
  height: calc((2 * var(--lh-m)));
}
.truncate-overflow::before {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
}
.truncate-overflow::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1rem;
  height: 1rem;
  background: var(--color-universal-natural-white);
}

/*one x*/
.oneX .file-details-buttons {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid var(--divider-color-context-background-primary);
}

@media (max-width: 1080px) {
  .file-details-buttons button {
    margin-right: 17px;
  }
}

@media (max-width: 767px) {
  .file-details-buttons .button {
    width: 150px;
  }

  .file-details-action-buttons {
    padding-top: var(--spacing-s);
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-l);
  }

  .file-details-bottom-row {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding-bottom: 30px;
  }

  .file-details-bottom-column .text-input {
    margin-bottom: 0px;
  }

  .file-details-action-buttons .button {
    width: 100%;
    margin-bottom: 20px;
  }

  .file-details-text:not(:last-child) {
    margin-bottom: 30px;
  }
}

.one-x .section-spacing {
  padding-top: 32px;
  margin-top: 32px;
}

.section-spacing .file-details-buttons {
  margin-bottom: var(--spacing-s);
}

.file-details-cancel-modal-inner {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: var(--fs-base);
}

.file-details-cancel-modal-button {
  float: right;
}
