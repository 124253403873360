input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.search-bar {
  width: 100%;
  position: relative;
}

.search-input {
  background-color: var(--search-color-interaction-background-primary);
  color: var(--search-color-interaction-text-primary-focus);
  border: 1px solid var(--search-color-interaction-border-primary);
  border-radius: var(--search-border-radius);
  height: 44px;
  width: 100%;
  gap: 20px;
  padding-right: 20px;
  padding-left: 55px;
  position: relative;
  font-size: var(--fs-s);
}

.search-input:hover {
  background: var(--search-color-interaction-background-primary-hover);
}

.search-input:focus {
  outline: 2px solid var(--search-color-interaction-border-primary-focus);
}

.search-input::placeholder {
  color: var(--search-color-interaction-text-primary);
}
.search-input.has-content {
  color: var(--search-color-interaction-text-primary-focus);
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-regular);
}
.search-bar .darker-icon svg {
  stroke: var(--search-color-interaction-text-primary-focus);
}

.search-bar .search-icon {
  position: absolute;
  left: 25px;
  top: 10px;
  display: inline-block;
  width: var(--icon-sizing-xs);
  height: var(--icon-sizing-xs);
  pointer-events: none;
}
.one-x .search-bar .search-icon {
  display: inline-block;
  width: var(--icon-sizing-xs);
  height: var(--icon-sizing-xs);
  pointer-events: none;
}
.search-bar .search-icon svg {
  fill: var(--search-color-interaction-background-primary);
  stroke: var(--search-color-interaction-text-primary);
  width: 100%;
  height: auto;
}

.search-bar .crossIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  height: 20px;
  width: 20px;
  padding: 0;
}

.search-bar .crossIcon:hover {
  cursor: pointer;
}

.search-bar .crossIcon svg {
  height: 16px;
  width: 16px;
}

.legado-menubar .search-bar.client-view {
  visibility: hidden;
}

.hide-double-search-bar {
  visibility: hidden;
}
