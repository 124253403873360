.grey-background-lozenge {
  background-color: var(--lozenge-color-feedback-background-primary);
}

.green-background-lozenge {
  background-color: var(--lozenge-color-feedback-background-positive);
}

.red-background-lozenge {
  background-color: var(--lozenge-color-feedback-background-negative);
}

.yellow-background-lozenge {
  background-color: var(--lozenge-color-feedback-background-emphasized);
}
