.grey-lozenge {
  background-color: var(--lozenge-color-feedback-background-primary);
  color: var(--lozenge-color-feedback-text-primary);
  font-weight: var(--fw-bold);
  outline: 1px solid var(--lozenge-color-feedback-text-pending);
  border-radius: 5px;
}

.green-lozenge {
  background-color: var(--lozenge-color-feedback-background-positive);
  color: var(--lozenge-color-feedback-text-positive);
  font-weight: var(--fw-bold);
  outline: 1px solid var(--lozenge-color-feedback-text-positive);
  border-radius: 5px;
}

.red-lozenge {
  background-color: var(--lozenge-color-feedback-background-revoke);
  color: var(--lozenge-color-feedback-text-revoke);
  font-weight: var(--fw-bold);
  border-radius: 5px;
}
