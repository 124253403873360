.page-content-search-bar {
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  position: relative;
  .crossIcon {
    position: absolute;
    top: 16px;
    right: 74px;
    height: 24px;
    width: 24px;
    padding: 0;
  }
  svg {
    height: 24px;
    width: 24px;
    fill: var(--search-color-interaction-text-primary-focus);
  }
  .crossIcon:focus-within {
    outline: 2px solid var(--color-input-outline-focus);
    outline-offset: 3px;
  }
}

.page-content-search-bar:focus-within {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
}

.page-content-search-bar .search-input {
  padding: 16px 12px;
  height: 60px;
  font-size: var(--fs-base);
  font-weight: var(--fw-regular);
  line-height: var(--lh-m);
  background-color: var(--search-color-interaction-background-primary);
  border: 2px solid var(--search-color-interaction-border-primary);
  color: var(--search-color-interaction-text-primary);
  border-radius: 5px 0px 0px 5px;

  &:focus {
    color: var(--search-color-interaction-text-primary-focus);
    outline: unset;
  }
}

.page-content-search-bar input.search-input::placeholder {
  color: var(--search-color-interaction-text-primary);
}

.page-content-search-bar .search-bar-button {
  width: 60px;
  height: 60px;
  background-color: var(--button-color-action-background-secondary);
  border-radius: 0px 5px 5px 0px;
  display: flex;
  align-items: center;
}

.page-content-search-bar .search-bar-button svg {
  stroke: var(--search-color-interaction-background-primary);
  margin: 0 auto;
}

.page-container__main-menu-content-header.mobile-tablet-only {
  padding: 20px 15px;
}
