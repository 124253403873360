.sharing-permissions {
  color: var(--color-universal-secondary-b);
}

.sharing-permissions .text-1,
.sharing-permissions .text-2,
.sharing-permissions .text-3,
.select-all-sharing-legend {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
  display: flex;
  gap: var(--spacing-xxx-s);
}

.sharing-permissions .text-1,
.select-all-group .select-all-sharing-legend {
  font-weight: var(--fw-medium);
  font-size: var(--fs-base);
}

.sharing-permissions .text-3 {
  font-weight: var(--fw-light);
  font-size: var(--fs-s);
}

.sharing-permissions .sharing-permissions-description {
  margin-top: var(--spacing-m);
  margin-bottom: 16px;
  font-weight: var(--fw-light);
}

.sharing-permissions .show-empty {
  margin-bottom: var(--spacing-m);
  padding: 0px;
}

.sharing-permissions .contact-sharing.radio-button-group {
  padding-bottom: var(--spacing-l);
  margin-top: var(--spacing-l);
  border-bottom: 1px solid var(--divider-color-context-background-primary);
}
/* tooltip manually tested and adjusted to fit all screen sizes without going beyond the screen */
.sharing-permissions .contact-sharing .tooltip-message {
  width: 250px;
  transform: translateX(calc(-50% + 6px));
}

/* none coop tooltip setup 
/* tooltip manually tested and adjusted to fit all screen sizes without going beyond the screen */
.sharing-permissions .select-all-group .tooltip-message,
.sharing-permissions .node-sharing .tooltip-message {
  width: 150px;
  transform: translateX(calc(-50% - 16px));
}

.sharing-permissions .node-sharing.radio-button-group {
  padding-bottom: var(--spacing-s);
  margin-top: var(--spacing-m);
  border-bottom: 1px dashed var(--divider-color-context-background-primary);
}

/* hiding select all row for add contact through single workflow document sharing */
.sharing-permissions .radio-button-group .hidden {
  display: none;
}

.sharing-permissions .radio-button-group:first-child {
  margin-top: var(--spacing-s);
}

.radio-group-legend {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxx-s);
}

.label-description {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-light);
}

.sharing-permissions .new-contact {
  margin-top: var(--spacing-m);
}

.sharing-permissions-modal-buttons {
  display: flex;
  position: relative;
  justify-content: flex-end;
}

.radio-button-group .radio-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-wrap: wrap;
}

.node-sharing.radio-button-group .radio-row:not(:first-child) {
  margin-top: var(--spacing-m);
  padding-bottom: var(--spacing-s);
  border-bottom: 1px solid var(--divider-color-context-background-primary);
}

.node-sharing.radio-button-group .radio-row:last-child,
.sharing-permissions .contact-sharing.radio-button-group:last-child {
  box-shadow: none;
  border-bottom: none;
}

.contact-sharing.radio-button-group .radio-row:not(:last-child) {
  padding-bottom: var(--spacing-s);
}

.radio-button-group .radio-div {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 1fr;
  justify-items: center;
}

.radio-button-group .radio-div label:not(.radio-header) {
  display: flex;
  gap: var(--spacing-xxx-s);
  place-items: center;
}

.radio-button-group .radio-div .radio-header {
  display: flex;
  align-items: center;
  gap: var(--spacing-xxx-s);
}

.radio-button-group .radio-div .radio-container {
  display: flex;
  justify-content: center;
}

.radio-button-group .radio-div .radio-container:focus-within {
  outline: 2px solid var(--color-input-outline-focus);
  outline-offset: 3px;
  border-radius: 100%;
}

.select-all-group {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-bottom: var(--spacing-m);
  border-bottom: 1px solid var(--divider-color-context-background-primary);
}

.select-all-radio {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-x-s);
  align-items: center;
}

.radio-button-group.node-sharing .infoButton svg {
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

.modal-content .pseudo-link-icon-left svg {
  fill: var(--color-universal-secondary-b);
  stroke: var(--color-universal-secondary-b);
}

@media (min-width: 768px) {
  .sharing-permissions .node-sharing .fieldset .radio-header {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

@media (max-width: 767px) {
  .sharing-permissions .text-1,
  .select-all-group .select-all-sharing-legend {
    margin-bottom: 35px;
  }

  .select-all-group .select-all-group {
    margin-bottom: var(--spacing-s);
  }

  .sharing-permissions .text-2,
  .radio-row .select-all-sharing-legend {
    font-size: var(--fs-base);
    line-height: var(--lh-base);
    margin-bottom: var(--spacing-x-s);
  }

  .sharing-permissions .text-3 {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
  }

  .sharing-permissions .sharing-permissions-description {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-s);
  }

  .sharing-permissions .show-empty {
    margin-bottom: var(--spacing-l);
  }

  .sharing-permissions .node-sharing.radio-button-group {
    padding-bottom: 0;
  }

  .node-sharing.radio-button-group .radio-row:not(:first-child) {
    margin-top: var(--spacing-l);
    padding-bottom: var(--spacing-l);
  }

  .contact-sharing.radio-button-group .radio-row {
    margin-top: var(--spacing-s);
  }

  .contact-sharing.radio-button-group .radio-row:last-child {
    padding-bottom: 0;
    box-shadow: none;
  }

  .sharing-permissions .contact-sharing.radio-button-group {
    border-bottom: 1px dashed var(--divider-color-context-background-primary);
  }

  .sharing-permissions .contact-sharing.radio-button-group:last-child {
    margin-bottom: var(--spacing-l);
  }

  .radio-group-legend {
    padding-bottom: var(--spacing-x-s);
    margin-bottom: 25px;
    border-bottom: 1px dashed var(--divider-color-context-background-lighter);
  }

  .sharing-permissions {
    margin-bottom: 110px;
  }

  .radio-button-group .radio-div {
    display: flex;
    justify-content: space-between;
  }

  .radio-button-group .radio-div.no-edit {
    max-width: 70%;
  }
}

.col-12.col-md-6.text-2 {
  font-size: var(--fs-s);
  word-break: break-all;
}

#select-all-sharing-legend {
  font-size: var(--fs-s);
}
