.review-description {
  padding-bottom: var(--standard-modal-gap);
  border-bottom: 1px dashed var(--divider-color-context-background-primary);
}

.review-text-m-light {
  font-size: var(--fs-m);
  line-height: var(--ls-m);
  font-weight: var(--fw-light);
  margin-bottom: 0;
}

.review-description h2 {
  font-size: var(--fs-l);
  line-height: var(--ls-l);
  font-weight: var(--fw-bold);
  margin: 0;
  margin-top: var(--standard-modal-gap);
}

.review-edit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--standard-modal-gap);
  gap: var(--spacing-xx-s);
}

.review-permissions-group {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-xxx-s);
  padding-bottom: var(--standard-modal-gap);
  border-bottom: 1px solid var(--divider-color-context-background-primary);
}

.review-permissions-group:not(:last-child) {
  margin-bottom: var(--standard-modal-gap);
}

.review-permissions-group .review-section-name {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
  font-weight: var(--fw-bold);
}

.review-permissions-group * {
  margin: 0;
}

.review-permissions-group .group-permission {
  font-size: var(--fs-xs);
  line-height: var(--lh-xs);
  font-weight: var(--fw-light);
}

.review-folders-permission:not(:last-child) {
  margin-bottom: var(--spacing-x-s);
}

@media (max-width: 767px) {
  .review-permissions-group {
    padding-bottom: var(--spacing-s);
  }

  .review-permissions-group:not(:last-child) {
    margin-bottom: var(--spacing-s);
  }

  .review-permissions-group .group-permission {
    font-size: var(--fs-s);
    line-height: var(--lh-s);
  }
}
