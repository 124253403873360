.upload-file-to-case-modal-wizard .modal-content-wrapper.with-scrollbar {
  padding-right: 10px;
}

.upload-file-to-case-modal-wizard .modal-content-wrapper .header {
  padding-bottom: 20px;
  font-weight: var(--fw-medium);
}

.upload-file-to-case-modal-wizard .modal-content-wrapper hr {
  color: var(--color-universal-secondary-d);
}

.upload-file-to-case-modal-wizard
  .modal-content-wrapper
  .upload-documents-divider {
  margin: 20px 0px 40px 0px;
}

.upload-file-to-case-modal-wizard .uploaded-documents__button-container {
  padding: 0 0 5px 5px;
}
/* TODO: Move to right place https://dev.azure.com/secure-the-file/Application/_workitems/edit/15783*/
.upload-file-to-case-modal-wizard .uploaded-documents__button-container button {
  width: fit-content;
}

.upload-file-to-case-modal-wizard .button-children,
.upload-file-to-case-modal-wizard .button-children label {
  cursor: pointer;
}

.upload-file-to-case-modal-wizard .pdf-div {
  height: 669px;
}

.upload-file-to-case-modal-wizard .pdf-div-loading,
.upload-file-to-case-modal-wizard .pdf-div,
.upload-file-to-case-modal-wizard .pdf-div-error {
  margin: 40px 0px;
}

.upload-file-to-case-modal-wizard button.primary,
.upload-file-to-case-modal-wizard button.secondary {
  height: 60px;
  padding: 16px 40px;
}

@media screen and (max-width: 375px) {
  .upload-file-to-case-modal-wizard button.primary,
  .upload-file-to-case-modal-wizard button.secondary {
    height: 50px;
    padding: 11px;
    padding-top: 11px;
    padding-bottom: 11px;
    padding-right: unset;
    padding-left: unset;
    width: 100%;
  }
}

.upload-file-to-case-modal-wizard button.primary span,
.upload-file-to-case-modal-wizard button.secondary span {
  font-size: var(--fs-base);
  line-height: var(--lh-base);
}

.upload-file-to-case-modal-wizard h2 {
  font-size: var(--fs-m);
  line-height: var(--lh-m);
}

.unsaved-changes-modal {
  .header {
    font-weight: var(--fw-medium);
  }

  .modal-content p {
    font-weight: var(--fw-regular);
    font-size: var(--fs-l);
    line-height: var(--lh-l);
  }

  .modal-button-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;

    button {
      padding: 16px 40px;

      span {
        font-size: var(--fs-base);
        line-height: var(--lh-base);
        font-weight: var(--fw-medium);
      }
    }

    @media screen and (max-width: 375px) {
      button {
        padding: 11px 11px;
        width: 100%;
      }
    }
  }
}
