.select-label {
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  font-weight: var(--fw-medium);
  display: block;
  margin-bottom: 5px;
}
.select-label.description {
  font-weight: var(--fw-light);
}

.form-select {
  cursor: pointer;
  padding-left: 1px;
}

.form-select .selected-value-field {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--spacing-s);
  background-color: var(--form-color-interaction-background-primary);
  color: var(--form-color-interaction-text-primary);
  padding: 6px var(--spacing-s);
  margin-top: 8px;
  border-radius: var(--border-radius-s);
  border: 1px solid var(--form-color-interaction-border-primary);
  width: 100%;
}

.form-select .selected-value-field:not(:placeholder-shown) {
  color: var(--form-color-interaction-text-primary-focus);
}

.form-select .selected-value-field[aria-expanded="false"]:focus {
  outline: 2px solid var(--form-color-interaction-border-primary-focus);
  border: 1px solid transparent;
}

.form-select .selected-value-field[aria-expanded="true"] {
  background-color: var(--form-color-interaction-background-primary);
  color: var(--color-universal-natural-white);
  border: 1px solid var(--form-color-interaction-border-primary);
}

.form-select .selected-value-field[aria-expanded="false"]:hover {
  background: var(--form-color-interaction-background-primary-hover);
}

.form-select .outer-wrapper .selected-value-field.error {
  border: 2px solid var(--component-color-feedback-danger);
}

.form-select .open-close-svg {
  width: var(--icon-sizing-xs);
  height: var(--icon-sizing-xs);
  top: 50%;
  transform: translateY(-50%);
}

.form-select .selected-value-field .open-close-svg {
  transform: translateY(0%);
}

.form-select .open-close-svg svg {
  width: 14px;
  height: 14px;
  display: block;
}

.form-select .selected-value-field[aria-expanded="true"] .open-close-svg svg {
  stroke: var(--color-universal-natural-white);
}

.form-select .select-list {
  background-color: var(--color-universal-natural-white);
  border-radius: 0 0 var(--border-radius-s) var(--border-radius-s);
  box-shadow: var(--shadow-2);
  margin-left: var(--spacing-xxx-s);
  overflow: hidden;
}

.form-select .select-option {
  display: flex;
  gap: var(--spacing-s);
  align-items: center;
  font-size: var(--fs-s);
  line-height: var(--lh-s);
  padding: 15px;
}
.form-select .open-close-svg .form-select .input:hover {
  cursor: pointer;
}
.form-select .select-option:hover,
.form-select .select-option[aria-selected="true"] {
  background-color: var(--form-color-interaction-background-primary-hover);
  cursor: pointer;
}

.form-select .select-option:not(:last-child) {
  border-bottom: 1px solid var(--divider-color-context-background-primary);
}

.form-select .select-option.with-radios .fake-radio {
  position: relative;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid var(--color-universal-secondary-c);
  box-shadow: 0px 2px 2px 0px rgba(204, 204, 204, 0.5);
}

.form-select .select-option.with-radios .fake-radio::before {
  content: "";
  position: absolute;
  margin: auto;
  top: 50%;
  left: 50%;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transform: scale(0);
  box-shadow: inset 12px 12px var(--color-universal-secondary-b);
}

.form-select
  .select-option.with-radios[aria-selected="true"]
  .fake-radio::before {
  transform: scale(1) translate(-50%, -50%);
}

.select-wrapper {
  width: 100%;
}

.select-wrapper .select-label {
  letter-spacing: 0.02em;
}

.select-label.required:before {
  content: "* ";
  color: var(--component-color-feedback-danger);
}
